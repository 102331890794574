import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, Typography, Button, Stack } from "@mui/material";
import TopBar from "../../components/TopBar/TopBar";
import onPrimary from "../../config/colors";
import { useTranslation } from "react-i18next";
import "./LandingPage.css";
import Footer from "../../components/Footer";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <div className="landing-page">
      <TopBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          paddingTop: "20vh",
        }}
      >
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            backgroundColor: onPrimary,
            padding: "5vh",
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ fontFamily: "Poppins", fontWeight: 600 }}
            >
              {t("yumgoPartner")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "Poppins",
                fontWeight: 400,
                marginTop: "2vh",
              }}
              align="center"
            >
              {t("manageRestaurant")}
            </Typography>
          </Box>
          <Stack spacing={1} direction="row" sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSignup}
              disableElevation
            >
              {t("getStarted")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleLogin}
              disableElevation
            >
              {t("login")}
            </Button>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default LandingPage;
