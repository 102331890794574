import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Snackbar,
  Alert,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import NoMealsIcon from "@mui/icons-material/NoMeals";
import apiHelper from "../helper/apiHelper";
import endpoints from "../config/apiConfig";
import MealDialog from "../components/MealDialog";
import { Meal } from "../config/types";
import { useTranslation } from "react-i18next";

const MealManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const vendorId = localStorage.getItem("vendorId") || "";
  const [meals, setMeals] = useState<Meal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [editingMeal, setEditingMeal] = useState<Meal | null>(null);
  const [newMeal, setNewMeal] = useState<Meal>({
    name: "",
    vendorId,
    description: "",
    ingredients: "",
    imageUrl: "",
    price: {
      currency: "HKD",
      originalPrice: 0,
      customerPrice: null,
      vendorPrice: 0,
    },
    breakfast: false,
    lunch: false,
    dinner: false,
    afternoonTea: false,
    available: true,
    active: false,
  });
  const [snackbar, setSnackbar] = useState<{
    severity: "success" | "error";
    message: string;
  } | null>(null);
  const [tabIndex, setTabIndex] = useState(0); // New state for tab selection

  const fetchMeals = useCallback(async () => {
    try {
      if (vendorId) {
        setLoading(true);
        const data = await apiHelper(endpoints.fetchMeals + vendorId, {
          method: "GET",
        });
        const sortedMeals = data.sort((a: Meal, b: Meal) =>
          a.name.localeCompare(b.name),
        );

        setMeals(sortedMeals);
      }
    } catch (error) {
      console.error("Failed to fetch meals:", error);
    } finally {
      setLoading(false);
    }
  }, [vendorId]);

  useEffect(() => {
    fetchMeals();
  }, [fetchMeals]);

  const handleOpen = (meal: Meal | null) => {
    setEditingMeal(meal);
    setNewMeal(
      meal || {
        name: "",
        vendorId,
        description: "",
        ingredients: "",
        imageUrl: "",
        price: {
          currency: "HKD",
          originalPrice: 0,
          customerPrice: null,
          vendorPrice: 0,
        },
        breakfast: false,
        lunch: false,
        dinner: false,
        afternoonTea: false,
        available: true,
        active: false,
      },
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingMeal(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    let updatedMeal = {
      ...newMeal,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name.startsWith("price.")) {
      const priceKey = name.split(".")[1];
      let updatedPriceValue = value;
      if (priceKey !== "currency" && value !== "") {
        updatedPriceValue = value.replace(/^0+(?!\.|$)/, ""); // Remove leading zeros
      }
      updatedMeal = {
        ...newMeal,
        price: {
          ...newMeal.price,
          [priceKey]: updatedPriceValue,
        },
      };
    }

    setNewMeal(updatedMeal);
  };

  const handleSave = async (fileKey: string) => {
    try {
      setLoading(true);
      // Filter out empty or whitespace-only drink option names
      const filteredDrinkOptions =
        newMeal.drinkOptionGroup?.options?.filter(
          (option) => option.name.trim() !== "",
        ) || [];

      if (editingMeal) {
        const body = {
          ...newMeal,
          imageUrl: fileKey,
          isAvailable: newMeal.available,
          isBreakfast: newMeal.breakfast,
          isLunch: newMeal.lunch,
          isDinner: newMeal.dinner,
          isAfternoonTea: newMeal.afternoonTea,
          drinkOptionGroup: filteredDrinkOptions.length
            ? {
                isRequired: newMeal.drinkOptionGroup?.isRequired ?? true,
                options: filteredDrinkOptions,
              }
            : {},
        };
        await apiHelper(endpoints.editMeal, {
          method: "PUT",
          body,
        });
      } else {
        const body = {
          ...newMeal,
          imageUrl: fileKey,
          isAvailable: newMeal.available,
          isBreakfast: newMeal.breakfast,
          isLunch: newMeal.lunch,
          isDinner: newMeal.dinner,
          isAfternoonTea: newMeal.afternoonTea,
          drinkOptionGroup: filteredDrinkOptions.length
            ? {
                isRequired: newMeal.drinkOptionGroup?.isRequired ?? true,
                options: filteredDrinkOptions,
              }
            : null,
        };
        await apiHelper(endpoints.addMeal, {
          method: "POST",
          body,
        });
      }
      await fetchMeals(); // Fetch meals again to refresh the list
      setSnackbar({
        severity: "success",
        message: t("MealManagementPage.Meal_Saved_Successfully"),
      });
    } catch (error) {
      console.error("Failed to save meal:", error);
      setSnackbar({
        severity: "error",
        message: t("MealManagementPage.Failed_To_Save_Meal"),
      });
    } finally {
      setLoading(false);
    }
  };

  const setAvailable = async (meal: Meal) => {
    try {
      handleClose();
      setLoading(true);
      let message = "";
      if (meal.available) {
        await apiHelper(`${endpoints.disableMeal}${vendorId}/${meal.id}`, {
          method: "DELETE",
        });
        message = t("MealManagementPage.Meal_Paused_Successfully");
      } else {
        await apiHelper(endpoints.editMeal, {
          method: "PUT",
          body: {
            ...meal,
            isAvailable: true,
            isBreakfast: meal.breakfast,
            isLunch: meal.lunch,
            isDinner: meal.dinner,
            isAfternoonTea: meal.afternoonTea,
          },
        });
        message = t("MealManagementPage.Meal_Set_Available_Successfully");
      }
      await fetchMeals(); // Fetch meals again to refresh the list
      setSnackbar({
        severity: "success",
        message,
      });
    } catch (error) {
      console.error("Failed to Pause meal:", error);
      setSnackbar({
        severity: "error",
        message: t("MealManagementPage.Failed_To_Pause_Meal"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="h1" variant="h5">
          {t("MealManagementPage.Manage_Meals")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(null)}
        >
          {t("MealManagementPage.Add_Meal")}
        </Button>
      </Box>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{ mt: 2 }}
      >
        <Tab label={t("MealManagementPage.Approved_Meals")} />
        <Tab label={t("MealManagementPage.Pending_Meals")} />
      </Tabs>

      {loading ? (
        <CircularProgress sx={{ mt: 4 }} />
      ) : (
        <>
          {tabIndex === 0 ? (
            <>
              <Typography
                variant="h6"
                sx={{ mt: 4, fontWeight: "bold", textDecoration: "underline" }}
              >
                {t("MealManagementPage.Available_Meals")}
              </Typography>
              {meals.filter((meal) => meal.active && meal.available).length ===
              0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <NoMealsIcon
                    sx={{ fontSize: 60, color: "gray", marginBottom: 2 }}
                  />
                  <Typography variant="h6" color="textSecondary">
                    {t("MealManagementPage.No_Available_Meals")}
                  </Typography>
                </Box>
              ) : (
                <Grid container spacing={5}>
                  {meals
                    .filter((meal) => meal.active && meal.available)
                    .map((meal) => (
                      <Grid item xs={12} sm={6} md={4} key={meal.id}>
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: 3,
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              endpoints.mealImagePrefix +
                              (meal.imageUrl || "default-food.png")
                            }
                            alt={meal.name}
                          />
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" gutterBottom>
                              {meal.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ mt: 1 }}
                            >
                              {meal.description}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ mt: 1 }}
                            >
                              {t("MealManagementPage.Price")}: $
                              {meal.price?.vendorPrice?.toFixed(2)}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleOpen(meal)}
                            >
                              {t("MealManagementPage.Edit")}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              )}

              <Divider sx={{ my: 4 }} />

              <Typography
                variant="h6"
                sx={{ my: 4, fontWeight: "bold", textDecoration: "underline" }}
              >
                {t("MealManagementPage.Paused_Meals")}
              </Typography>
              {meals.filter((meal) => meal.active && !meal.available).length ===
              0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <NoMealsIcon
                    sx={{ fontSize: 60, color: "gray", marginBottom: 2 }}
                  />
                  <Typography variant="h6" color="textSecondary">
                    {t("MealManagementPage.No_Paused_Meals")}
                  </Typography>
                </Box>
              ) : (
                <Grid container spacing={5}>
                  {meals
                    .filter((meal) => meal.active && !meal.available)
                    .map((meal) => (
                      <Grid item xs={12} sm={6} md={4} key={meal.id}>
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: 1,
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              endpoints.mealImagePrefix +
                              (meal.imageUrl || "default-food.png")
                            }
                            alt={meal.name}
                          />
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" gutterBottom>
                              {meal.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ mt: 1 }}
                            >
                              {meal.description}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ mt: 1 }}
                            >
                              {t("MealManagementPage.Price")}: $
                              {meal.price?.vendorPrice?.toFixed(2)}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleOpen(meal)}
                            >
                              {t("MealManagementPage.Edit")}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              )}
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                sx={{ mt: 4, fontWeight: "bold", textDecoration: "underline" }}
              >
                {t("MealManagementPage.Pending_Meals")}
              </Typography>
              {meals.filter((meal) => !meal.active).length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <NoMealsIcon
                    sx={{ fontSize: 60, color: "gray", marginBottom: 2 }}
                  />
                  <Typography variant="h6" color="textSecondary">
                    {t("MealManagementPage.No_Pending_Meals")}
                  </Typography>
                </Box>
              ) : (
                <Grid container spacing={5}>
                  {meals
                    .filter((meal) => !meal.active)
                    .map((meal) => (
                      <Grid item xs={12} sm={6} md={4} key={meal.id}>
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: 3,
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              endpoints.mealImagePrefix +
                              (meal.imageUrl || "default-food.png")
                            }
                            alt={meal.name}
                          />
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" gutterBottom>
                              {meal.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ mt: 1 }}
                            >
                              {meal.description}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{ mt: 1 }}
                            >
                              {t("MealManagementPage.Price")}: $
                              {meal.price?.vendorPrice?.toFixed(2)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              )}
            </>
          )}
        </>
      )}
      <MealDialog
        open={open}
        meal={newMeal}
        onClose={handleClose}
        onChange={handleChange}
        onSave={handleSave}
        setAvailable={setAvailable}
        setNewMeal={setNewMeal}
      />
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbar(null)} severity={snackbar?.severity}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MealManagementPage;
