import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { Meal } from "../config/types";
import endpoints from "../config/apiConfig";
import "@aws-amplify/ui-react/styles.css";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

interface MealDialogProps {
  open: boolean;
  meal: Meal;
  onClose: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: (fileKey: string) => void;
  setAvailable: (meal: Meal) => void;
  setNewMeal: (meal: Meal) => void;
}

const MealDialog: React.FC<MealDialogProps> = ({
  open,
  meal,
  onClose,
  onChange,
  onSave,
  setAvailable,
  setNewMeal,
}) => {
  const { t, i18n } = useTranslation();
  const [fileKey, setFileKey] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [confirmAction, setConfirmAction] = useState<
    "Pause" | "SetAvailable" | null
  >(null);
  const [drinkOptions, setDrinkOptions] = useState<
    { name: string; priceAdjustment: string }[]
  >([]); // Default to one empty option

  // Limit the number of drink options to 5
  const maxOptions = 5;

  // Handle adding a new drink option
  const handleAddOption = () => {
    if (drinkOptions.length < maxOptions) {
      const updatedOptions = [
        ...drinkOptions,
        { name: "", priceAdjustment: "0" },
      ];
      setDrinkOptions(updatedOptions);

      const updatedMeal = { ...meal };
      updatedMeal.drinkOptionGroup = {
        isRequired: true,
        options: updatedOptions,
      };
      setNewMeal(updatedMeal);
    }
  };

  // Handle removing a drink option
  const handleRemoveOption = (index: number) => {
    const updatedOptions = drinkOptions.filter((_, i) => i !== index);
    setDrinkOptions(updatedOptions);

    const updatedMeal = { ...meal };
    updatedMeal.drinkOptionGroup = {
      isRequired: true,
      options: updatedOptions,
    };
    setNewMeal(updatedMeal);
  };

  // Handle changes in the drink options input fields
  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...drinkOptions];
    updatedOptions[index]["name"] = value;
    setDrinkOptions(updatedOptions);

    const updatedMeal = { ...meal };
    updatedMeal.drinkOptionGroup = {
      isRequired: true,
      options: updatedOptions,
    };
    setNewMeal(updatedMeal);
  };

  const processFile = async ({ file }: any) => {
    setStatus("uploading");
    const fileName = file.name.split(".");
    const fileExtension = fileName.pop();
    const filePrefix = fileName.pop(0);

    return file
      .arrayBuffer()
      .then((filebuffer: any) =>
        window.crypto.subtle.digest("SHA-1", filebuffer),
      )
      .then(() => {
        const key = `${filePrefix}-${meal.vendorId}.${fileExtension}`;
        setFileKey(key);
        return { file, key };
      });
  };

  useEffect(() => {
    if (meal.imageUrl) {
      setFileKey(meal.imageUrl);
    } else {
      setFileKey("");
    }
  }, [meal.imageUrl]);

  useEffect(() => {
    if (meal.drinkOptionGroup) {
      setDrinkOptions(meal.drinkOptionGroup.options);
    } else {
      setDrinkOptions([{ name: "", priceAdjustment: "0" }]);
    }
  }, [meal]);

  const handleFileRemove = () => {
    setFileKey("");
  };

  const closeDialog = () => {
    onClose();
    if (meal.id) {
      setFileKey(meal.imageUrl);
    } else {
      setFileKey("");
    }
    setStatus("");
    setConfirmAction(null);

    if (meal.drinkOptionGroup) {
      setDrinkOptions(meal.drinkOptionGroup.options);
    } else {
      setDrinkOptions([{ name: "", priceAdjustment: "0" }]);
    }
  };

  const dictionary = {
    en: {
      getFilesUploadedText(count: any) {
        return t("StorageManager.getFilesUploadedText", { count });
      },
      getFileSizeErrorText(sizeText: any) {
        return t("StorageManager.getFileSizeErrorText", { sizeText });
      },
      getRemainingFilesText(count: any) {
        return t("StorageManager.getRemainingFilesText", { count });
      },
      getUploadButtonText(count: any) {
        return t("StorageManager.getUploadButtonText", { count });
      },
      getMaxFilesErrorText(count: any) {
        return t("StorageManager.getMaxFilesErrorText", { count });
      },
      getErrorText(message: any) {
        return t("StorageManager.getErrorText", { message });
      },
      doneButtonText: t("StorageManager.doneButtonText"),
      clearAllButtonText: t("StorageManager.clearAllButtonText"),
      extensionNotAllowedText: t("StorageManager.extensionNotAllowedText"),
      browseFilesText: t("StorageManager.browseFilesText"),
      dropFilesText: t("StorageManager.dropFilesText"),
      pauseButtonText: t("StorageManager.pauseButtonText"),
      resumeButtonText: t("StorageManager.resumeButtonText"),
      uploadSuccessfulText: t("StorageManager.uploadSuccessfulText"),
      getPausedText(percentage: any) {
        return t("StorageManager.getPausedText", { percentage });
      },
    },
    "zh-Hans": {
      getFilesUploadedText(count: any) {
        return t("StorageManager.getFilesUploadedText", { count });
      },
      getFileSizeErrorText(sizeText: any) {
        return t("StorageManager.getFileSizeErrorText", { sizeText });
      },
      getRemainingFilesText(count: any) {
        return t("StorageManager.getRemainingFilesText", { count });
      },
      getUploadButtonText(count: any) {
        return t("StorageManager.getUploadButtonText", { count });
      },
      getMaxFilesErrorText(count: any) {
        return t("StorageManager.getMaxFilesErrorText", { count });
      },
      getErrorText(message: any) {
        return t("StorageManager.getErrorText", { message });
      },
      doneButtonText: t("StorageManager.doneButtonText"),
      clearAllButtonText: t("StorageManager.clearAllButtonText"),
      extensionNotAllowedText: t("StorageManager.extensionNotAllowedText"),
      browseFilesText: t("StorageManager.browseFilesText"),
      dropFilesText: t("StorageManager.dropFilesText"),
      pauseButtonText: t("StorageManager.pauseButtonText"),
      resumeButtonText: t("StorageManager.resumeButtonText"),
      uploadSuccessfulText: t("StorageManager.uploadSuccessfulText"),
      getPausedText(percentage: any) {
        return t("StorageManager.getPausedText", { percentage });
      },
    },
    "zh-Hant": {
      getFilesUploadedText(count: any) {
        return t("StorageManager.getFilesUploadedText", { count });
      },
      getFileSizeErrorText(sizeText: any) {
        return t("StorageManager.getFileSizeErrorText", { sizeText });
      },
      getRemainingFilesText(count: any) {
        return t("StorageManager.getRemainingFilesText", { count });
      },
      getUploadButtonText(count: any) {
        return t("StorageManager.getUploadButtonText", { count });
      },
      getMaxFilesErrorText(count: any) {
        return t("StorageManager.getMaxFilesErrorText", { count });
      },
      getErrorText(message: any) {
        return t("StorageManager.getErrorText", { message });
      },
      doneButtonText: t("StorageManager.doneButtonText"),
      clearAllButtonText: t("StorageManager.clearAllButtonText"),
      extensionNotAllowedText: t("StorageManager.extensionNotAllowedText"),
      browseFilesText: t("StorageManager.browseFilesText"),
      dropFilesText: t("StorageManager.dropFilesText"),
      pauseButtonText: t("StorageManager.pauseButtonText"),
      resumeButtonText: t("StorageManager.resumeButtonText"),
      uploadSuccessfulText: t("StorageManager.uploadSuccessfulText"),
      getPausedText(percentage: any) {
        return t("StorageManager.getPausedText", { percentage });
      },
    },
  };
  return (
    <Dialog open={open} onClose={closeDialog}>
      {confirmAction ? (
        <DialogTitle variant="h5">
          {t("MealDialog." + confirmAction)}
        </DialogTitle>
      ) : (
        <DialogTitle variant="h5">
          {meal.id ? t("MealDialog.EditMeal") : t("MealDialog.AddNewMeal")}
        </DialogTitle>
      )}
      <DialogContent>
        {confirmAction ? (
          <Box mt={1}>
            <Typography sx={{ mb: 2 }}>
              {confirmAction === "Pause"
                ? t("MealDialog.ConfirmPause")
                : t("MealDialog.ConfirmSetAvailable")}
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {meal.name}
            </Typography>
            <img
              src={endpoints.mealImagePrefix + fileKey}
              alt="Meal"
              style={{ maxHeight: 300, maxWidth: 300 }}
            />
          </Box>
        ) : (
          <>
            <TextField
              margin="dense"
              name="name"
              label={t("MealDialog.Name")}
              type="text"
              fullWidth
              variant="outlined"
              value={meal.name}
              onChange={onChange}
              disabled={!meal.available}
            />
            <TextField
              margin="dense"
              name="description"
              label={t("MealDialog.Description")}
              type="text"
              fullWidth
              variant="outlined"
              value={meal.description}
              onChange={onChange}
              disabled={!meal.available}
            />
            <TextField
              margin="dense"
              name="ingredients"
              label={t("MealDialog.Ingredients")}
              type="text"
              fullWidth
              variant="outlined"
              value={meal.ingredients}
              onChange={onChange}
              disabled={!meal.available}
            />
            <TextField
              margin="dense"
              name="price.originalPrice"
              label={t("MealDialog.OriginalPrice")}
              type="number"
              fullWidth
              variant="outlined"
              value={meal.price.originalPrice || ""}
              onChange={onChange}
              sx={{ mb: 2 }}
              InputProps={{
                inputProps: { min: 0 },
              }}
              disabled={!!meal.id || !meal.available}
            />
            <TextField
              margin="dense"
              name="price.vendorPrice"
              label={t("MealDialog.VendorPrice")}
              type="number"
              fullWidth
              variant="outlined"
              value={meal.price.vendorPrice || ""}
              onChange={onChange}
              sx={{ mb: 2 }}
              InputProps={{
                inputProps: { min: 0 },
              }}
              disabled={!!meal.id || !meal.available}
            />
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {t("MealDialog.DrinkOptions")}
              </Typography>
              {drinkOptions.map((option, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <TextField
                    label={t("MealDialog.OptionName")}
                    variant="outlined"
                    value={option.name}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    fullWidth
                    sx={{ mr: 2 }}
                  />
                  <IconButton
                    onClick={() => handleRemoveOption(index)}
                    disabled={drinkOptions.length <= 1} // Ensure at least one option exists
                    sx={{ ml: 2 }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                onClick={handleAddOption}
                disabled={drinkOptions.length >= maxOptions}
                variant="contained"
                sx={{ mt: 2 }}
              >
                <AddCircleIcon sx={{ mr: 1 }} />
                {t("MealDialog.AddOption")}
              </Button>
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={meal.breakfast}
                  onChange={onChange}
                  name="breakfast"
                  disabled={!meal.available || meal.active}
                />
              }
              label={t("MealDialog.Breakfast")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={meal.lunch}
                  onChange={onChange}
                  name="lunch"
                  disabled={!meal.available || meal.active}
                />
              }
              label={t("MealDialog.Lunch")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={meal.dinner}
                  onChange={onChange}
                  name="dinner"
                  disabled={!meal.available || meal.active}
                />
              }
              label={t("MealDialog.Dinner")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={meal.afternoonTea}
                  onChange={onChange}
                  name="afternoonTea"
                  disabled={!meal.available || meal.active}
                />
              }
              label={t("MealDialog.AfternoonTea")}
            />
            {fileKey && status !== "uploading" && (
              <Box>
                <Box mt={2}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {t("MealDialog.ImagePreview")}
                  </Typography>
                  <img
                    src={endpoints.mealImagePrefix + fileKey}
                    alt="Meal"
                    style={{ maxHeight: 300, maxWidth: 300 }}
                  />
                </Box>
                <Button
                  onClick={handleFileRemove}
                  variant="contained"
                  sx={{ mt: 1 }}
                  disabled={!meal.available}
                >
                  {t("MealDialog.RemoveImage")}
                </Button>
              </Box>
            )}
            {(!fileKey || status === "uploading") && (
              <Box>
                <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>
                  {t("MealDialog.UploadMealImage")}
                </Typography>
                <StorageManager
                  acceptedFileTypes={["image/*"]}
                  path="public/"
                  maxFileCount={1}
                  isResumable
                  processFile={processFile}
                  onUploadError={() => {
                    setStatus("error");
                  }}
                  onUploadSuccess={() => {
                    setStatus("success");
                  }}
                  onUploadStart={() => {
                    setStatus("uploading");
                  }}
                  onFileRemove={() => {
                    handleFileRemove();
                  }}
                  displayText={
                    dictionary[i18n.language as "en" | "zh-Hans" | "zh-Hant"]
                  }
                />
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="inherit" variant="contained">
          {t("MealDialog.Cancel")}
        </Button>
        {meal.id && !confirmAction && (
          <Button
            variant="contained"
            color={meal.available ? "error" : "primary"}
            onClick={() =>
              setConfirmAction(meal.available ? "Pause" : "SetAvailable")
            }
          >
            {meal.available
              ? t("MealDialog.Pause")
              : t("MealDialog.SetAvailable")}
          </Button>
        )}
        {confirmAction && (
          <Button
            onClick={() => {
              closeDialog();
              setAvailable(meal);
            }}
            color="primary"
            variant="contained"
          >
            {t("MealDialog.Confirm")}
          </Button>
        )}
        {!confirmAction && (!meal || meal.available) && (
          <Button
            onClick={() => {
              closeDialog();
              onSave(fileKey);
            }}
            color="primary"
            variant="contained"
            disabled={
              !meal.name ||
              !meal.price.originalPrice ||
              !meal.price.vendorPrice ||
              status === "uploading"
            }
          >
            {t("MealDialog.Save")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MealDialog;
