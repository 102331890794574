import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, AppDispatch } from "../redux/auth/store";
import { loginAsync } from "../redux/auth/authAPI";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Container,
  Box,
  Alert,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TopBar from "../components/TopBar/TopBar";
import onPrimary from "../config/colors";
import "./LandingPage/LandingPage.css";
import { signIn, signOut } from "aws-amplify/auth";
import VendorRegistration from "../components/VendorRegistration";
import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import Footer from "../components/Footer";

const Login: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogin = async () => {
    if (!email.trim() || !password.trim()) {
      setError(t("Both_email_and_password_required"));
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await signOut();
      const { nextStep } = await signIn({
        username: email,
        password,
      });
      if (
        nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setStep(1); // Move to password setup step
      } else {
        await dispatch(loginAsync({ email })).unwrap();
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError(t("Login_failed"));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="landing-page">
      <TopBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          paddingTop: "20vh",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: onPrimary,
            padding: "5vh",
            borderRadius: "15px", // rounded corners
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // subtle shadow
            border: "1px solid rgba(0, 0, 0, 0.1)", // subtle border
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              top: "10px",
              left: "10px",
            }}
          >
            <ArrowBack />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {step === 0 ? (
              <>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontFamily: "Poppins", fontWeight: 600 }}
                >
                  {t("Login_to_Yumgo_Partner")}
                </Typography>
                <Box component="form" sx={{ mt: 3 }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t("Email_Address")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t("Password")}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Link
                      onClick={handleForgotPassword}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography variant="body2">
                        {t("Forgot_password")}
                      </Typography>
                    </Link>
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={loading}
                    onClick={handleLogin}
                  >
                    {loading ? <CircularProgress size={24} /> : t("Login")}
                  </Button>
                  {error && (
                    <Box mt={2}>
                      <Alert severity="error">{error}</Alert>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <VendorRegistration
                email={email}
                onComplete={() => {
                  setStep(0);
                  setPassword("");
                }}
              />
            )}
          </Box>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default Login;
