import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography,
  Box,
  Avatar,
  Paper,
  TableSortLabel,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import apiHelper from "../helper/apiHelper";
import endpoints from "../config/apiConfig";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { localeMap, OrderResponse, timezone } from "../config/types";
import {
  CheckCircle,
  Cancel,
  HourglassEmpty,
  LocalShipping,
} from "@mui/icons-material";
import { enUS } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

const VendorOrders = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const locale =
    localeMap[i18n.language as "en" | "zh-Hans" | "zh-Hant"] || enUS;
  const vendorId = localStorage.getItem("vendorId") || "";
  const hkDate = utcToZonedTime(new Date(), timezone); // Get current date in HK timezone
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(hkDate);
  const [status, setStatus] = useState("ORDER_PENDING");
  const [orderBy, setOrderBy] = useState("pickupTime");
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        if (vendorId) {
          const formattedDate = formatInTimeZone(
            date,
            timezone,
            "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
          );

          const data = await apiHelper(endpoints.getOrdersByDate + vendorId, {
            method: "POST",
            body: {
              date: formattedDate,
              orderStatus: status,
            },
          });
          setOrders(data);
        }
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [date, status, vendorId]);

  const handleDateChange = (newDate: any) => {
    setDate(newDate);
  };

  const handleStatusChange = (event: any) => {
    setStatus(event.target.value);
  };

  const getStatusIcon = (status: any) => {
    switch (status) {
      case "ORDER_COMPLETED":
        return <CheckCircle style={{ color: "green" }} />;
      case "ORDER_CANCELLED":
        return <Cancel style={{ color: "red" }} />;
      case "ON_DELIVERY":
        return <LocalShipping style={{ color: "blue" }} />;
      default:
        return <HourglassEmpty style={{ color: "orange" }} />;
    }
  };

  const handleSortRequest = (property: string) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedOrders = [...orders].sort(
    (a: OrderResponse, b: OrderResponse) => {
      let comparison = 0;
      switch (orderBy) {
        case "mealName":
          comparison = a.mealName.localeCompare(b.mealName);
          break;
        case "orderId":
          comparison = a.externalOrderId.localeCompare(b.externalOrderId);
          break;
        case "orderTime":
          comparison =
            new Date(a.orderTime).getTime() - new Date(b.orderTime).getTime();
          break;
        case "pickupTime":
          comparison =
            new Date(a.pickupTime).getTime() - new Date(b.pickupTime).getTime();
          break;
        case "mealVendorPrice":
          comparison = a.mealVendorPrice - b.mealVendorPrice;
          break;
        case "status":
          comparison = a.status.localeCompare(b.status);
          break;
        default:
          break;
      }
      return orderDirection === "asc" ? comparison : -comparison;
    },
  );

  return (
    <Box
      sx={{
        maxWidth: isMobile ? "90vw" : "auto", // Ensure container width adapts to mobile
      }}
    >
      <Box
        sx={{
          transform: isMobile ? "scale(0.5)" : "none", // Scale content on mobile
          transformOrigin: "top left",
          width: isMobile ? "200%" : "100%", // Counteract the scale effect for width
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          sx={{ ml: 5, mr: 5, mb: 5 }}
        >
          <Typography variant="h5">{t("vendorOrders.title")}</Typography>
          <Box display="flex" alignItems="center">
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color="primary"
              onClick={() => navigate(`/vendor-orders/report`)}
            >
              {t("vendorOrders.payout_report")}
            </Button>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale}
            >
              <DatePicker
                value={date}
                onChange={handleDateChange}
                timezone="Asia/Hong_Kong"
                disableHighlightToday
              />
            </LocalizationProvider>
            <FormControl variant="outlined" sx={{ ml: 2, minWidth: 120 }}>
              <InputLabel>{t("vendorOrders.status")}</InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange}
                label={t("vendorOrders.status")}
              >
                <MenuItem value="ORDER_PENDING">
                  {t("vendorOrders.pending")}
                </MenuItem>
                <MenuItem value="ORDER_FULFILLED">
                  {t("vendorOrders.fulfilled")}
                </MenuItem>
                <MenuItem value="ORDER_CANCELLED">
                  {t("vendorOrders.cancelled")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {loading ? (
          <CircularProgress sx={{ ml: 5 }} />
        ) : (
          <Paper elevation={3} sx={{ ml: 5, mr: 5 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "mealName"}
                      direction={
                        orderBy === "mealName" ? orderDirection : "asc"
                      }
                      onClick={() => handleSortRequest("mealName")}
                    >
                      <Typography sx={{ fontWeight: "bold" }} variant="body1">
                        {t("vendorOrders.mealName")}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "orderId"}
                      direction={orderBy === "orderId" ? orderDirection : "asc"}
                      onClick={() => handleSortRequest("orderId")}
                    >
                      <Typography sx={{ fontWeight: "bold" }} variant="body1">
                        {t("vendorOrders.orderId")}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "orderTime"}
                      direction={
                        orderBy === "orderTime" ? orderDirection : "asc"
                      }
                      onClick={() => handleSortRequest("orderTime")}
                    >
                      <Typography sx={{ fontWeight: "bold" }} variant="body1">
                        {t("vendorOrders.orderTime")}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "pickupTime"}
                      direction={
                        orderBy === "pickupTime" ? orderDirection : "asc"
                      }
                      onClick={() => handleSortRequest("pickupTime")}
                    >
                      <Typography sx={{ fontWeight: "bold" }} variant="body1">
                        {t("vendorOrders.pickupTime")}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "mealVendorPrice"}
                      direction={
                        orderBy === "mealVendorPrice" ? orderDirection : "asc"
                      }
                      onClick={() => handleSortRequest("mealVendorPrice")}
                    >
                      <Typography sx={{ fontWeight: "bold" }} variant="body1">
                        {t("vendorOrders.amount")}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "status"}
                      direction={orderBy === "status" ? orderDirection : "asc"}
                      onClick={() => handleSortRequest("status")}
                    >
                      <Typography sx={{ fontWeight: "bold" }} variant="body1">
                        {t("vendorOrders.status")}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedOrders.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography variant="h6" align="center">
                        {t("vendorOrders.noOrdersFound")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedOrders.map((order: OrderResponse) => {
                    const orderTime = new Date(order.orderTime);
                    const pickupTime = new Date(order.pickupTime);

                    return (
                      <TableRow
                        key={order.orderId}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                        }}
                      >
                        <TableCell>
                          <Avatar
                            src={`${endpoints.mealImagePrefix}${order.imageUrl}`}
                            alt={order.mealName}
                            variant="rounded"
                            sx={{ width: 56, height: 56 }}
                          />
                        </TableCell>
                        <TableCell>{order.mealName}</TableCell>
                        <TableCell>{order.externalOrderId}</TableCell>
                        <TableCell>
                          {format(orderTime, "dd MMMM yyyy, hh:mm a")}
                        </TableCell>
                        <TableCell>
                          {format(pickupTime, "dd MMMM yyyy, hh:mm a")}
                        </TableCell>
                        <TableCell>
                          ${order.mealVendorPrice.toFixed(2)}
                        </TableCell>
                        <TableCell>{getStatusIcon(order.status)}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default VendorOrders;
