import AWS from "aws-sdk";

export const API_GATEWAY_URL =
  "https://8195i780va.execute-api.ap-northeast-1.amazonaws.com/prod/api/v1";

export const VENDOR_SERVICE_URL = API_GATEWAY_URL;
export const ORDER_SERVICE_URL = API_GATEWAY_URL;

// export const VENDOR_SERVICE_URL = "http://192.168.1.110:8084/api/v1";
// export const ORDER_SERVICE_URL = "http://192.168.1.110:8085/api/v1";

export const GOOGLE_PLACE_API_KEY = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

export const endpoints = {
  mealImagePrefix: process.env.REACT_APP_MEAL_IMAGE_PREFIX!,
  fetchMeals: `${VENDOR_SERVICE_URL}/vendors/fetchMeals/`,
  addMeal: `${VENDOR_SERVICE_URL}/vendors/addMeal`,
  editMeal: `${VENDOR_SERVICE_URL}/vendors/editMeal`,
  disableMeal: `${VENDOR_SERVICE_URL}/vendors/disableMeal/`,
  setOperationHours: `${VENDOR_SERVICE_URL}/vendors/setOperationHours`,
  getOperationHours: `${VENDOR_SERVICE_URL}/vendors/getOperationHours/`,
  saveMealSchedule: `${VENDOR_SERVICE_URL}/vendor/meals/saveMealSchedule`,
  getMealSchedule: `${VENDOR_SERVICE_URL}/vendor/meals/getMealSchedule`,
  getMealScheduleOverview: `${VENDOR_SERVICE_URL}/vendor/meals/getMealScheduleOverview`,
  saveAsMealSchedulePreset: `${VENDOR_SERVICE_URL}/vendor/meals/saveAsMealSchedulePreset`,
  addSchedulePreset: `${VENDOR_SERVICE_URL}/vendor/meals/addSchedulePreset`,
  saveSchedulePreset: `${VENDOR_SERVICE_URL}/vendor/meals/saveSchedulePreset/`,
  getPresetSummaries: `${VENDOR_SERVICE_URL}/vendor/meals/presetSummaries/`,
  getPresetDetail: `${VENDOR_SERVICE_URL}/vendor/meals/preset/`,
  deletePreset: `${VENDOR_SERVICE_URL}/vendor/meals/deleteSchedulePreset/`,
  addVendor: `${VENDOR_SERVICE_URL}/vendors/add`,
  getDetailsByPlaceId: `${VENDOR_SERVICE_URL}/vendors/getDetailsByPlaceId`,
  confirmStatus: `${VENDOR_SERVICE_URL}/vendors/confirmStatus`,
  vendorSettings: `${VENDOR_SERVICE_URL}/vendor/meals/settings/`,
  unblockDate: `${VENDOR_SERVICE_URL}/vendor/meals/unblockDate`,
  blockDate: `${VENDOR_SERVICE_URL}/vendor/meals/blockDate`,
  getVendorInfo: `${VENDOR_SERVICE_URL}/vendors/info`,
  updateVendorInfo: `${VENDOR_SERVICE_URL}/vendors/updateVendorInfo`,
  getOrdersByDate: `${ORDER_SERVICE_URL}/vendor/orders/getOrdersByDate/`,
  getOrdersFromDateRange: `${ORDER_SERVICE_URL}/vendor/orders/getOrdersFromDateRange/`,
};

export const s3 = new AWS.S3({
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});
export default endpoints;
