import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/theme";
import Login from "./pages/LoginPage";
import Home from "./pages/Home";
import Signup from "./pages/SignupPage";
import MainLayout from "./components/MainLayout";
import PrivateRoute from "./components/PrivateRoute";
import LandingPage from "./pages/LandingPage/LandingPage";
import { useSelector } from "react-redux";
import { RootState } from "./redux/auth/store";
import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";
import VendorProfilePage from "./pages/VendorProfilePage";
import MealManagementPage from "./pages/MealManagementPage";
import MealScheduleOverviewPage from "./pages/MealScheduleOverviewPage";
import MealSchedulePresetPage from "./pages/MealSchedulePresetPage";
import EditMealSchedulePresetPage from "./pages/EditMealSchedulePresetPage";
import VendorOrders from "./pages/VendorOrdersPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import PayoutReport from "./pages/PayoutReport";
import VendorOperationHoursPage from "./pages/VendorOperationHoursPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

Amplify.configure(awsmobile);

function App() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/landing" element={<LandingPage />} />
          <Route
            path="*"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/landing" replace />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <MainLayout>
                  <Home />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor-profile"
            element={
              <PrivateRoute>
                <MainLayout>
                  <VendorProfilePage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/operation-hours"
            element={
              <PrivateRoute>
                <MainLayout>
                  <VendorOperationHoursPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/meal-management"
            element={
              <PrivateRoute>
                <MainLayout>
                  <MealManagementPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/meal-schedule-overview"
            element={
              <PrivateRoute>
                <MainLayout>
                  <MealScheduleOverviewPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/meal-schedule-preset"
            element={
              <PrivateRoute>
                <MainLayout>
                  <MealSchedulePresetPage />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-preset/:presetId"
            element={
              <PrivateRoute>
                <MainLayout>
                  <EditMealSchedulePresetPage mode="edit" />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/add-preset"
            element={
              <PrivateRoute>
                <MainLayout>
                  <EditMealSchedulePresetPage mode="add" />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor-orders"
            element={
              <PrivateRoute>
                <MainLayout>
                  <VendorOrders />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/vendor-orders/report"
            element={
              <PrivateRoute>
                <MainLayout>
                  <PayoutReport />
                </MainLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
