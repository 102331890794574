import { MealScheduleDetail } from "../config/types";

export const initializePresetDays = (
  meals: Record<string, MealScheduleDetail[]> = {},
): Record<string, MealScheduleDetail[]> => {
  const daysOfWeek = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  const initializedMeals: Record<string, MealScheduleDetail[]> = {};

  daysOfWeek.forEach((day) => {
    initializedMeals[day] = meals[day] || [];
  });

  return initializedMeals;
};

export const preparePresetMealData = (
  weeklyMeals: Record<string, MealScheduleDetail[]>,
  selectedMeals: Record<string, string[][]>,
  mealType: "BREAKFAST" | "LUNCH" | "DINNER" | "AFTERNOON_TEA",
) => {
  const daysOfWeek = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];
  return daysOfWeek.reduce(
    (acc, day, index) => {
      const mealsForDay = weeklyMeals[day] || [];
      const selectedMealIds = selectedMeals[mealType][index];
      acc[day] = mealsForDay.filter(
        (meal) => selectedMealIds.includes(meal.mealId) && meal.count > 0,
      );
      return acc;
    },
    {} as Record<string, MealScheduleDetail[]>,
  );
};
