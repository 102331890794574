import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar from "../components/TopBar/TopBar";
import "./LandingPage/LandingPage.css";
import onPrimary from "../config/colors";
import GooglePlacesAutocomplete from "@dylmye/mui-google-places-autocomplete";
import apiHelper from "../helper/apiHelper";
import endpoints, { GOOGLE_PLACE_API_KEY } from "../config/apiConfig";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import Footer from "../components/Footer";

const Signup: React.FC = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    vendorName: "",
    firstName: "",
    lastName: "",
    location: "",
    formattedAddress: "",
    placeId: "",
    unitNumber: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: false,
    phoneNumber: false,
    vendorName: false,
    firstName: false,
    lastName: false,
    location: false,
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneNumberChange = (newValue: string) => {
    setFormData({
      ...formData,
      phoneNumber: newValue,
    });
  };

  const handleLocationChange = (newValue: string) => {
    setFormData({
      ...formData,
      location: newValue,
    });
  };

  const handleSelectedLocation = (option: any) => {
    if (option) {
      setSelectedOption(option);
      setFormData({
        ...formData,
        location: option.description,
        placeId: option.place_id,
        formattedAddress: option.description,
      });
    } else {
      setSelectedOption(null);
      setFormData({
        ...formData,
        location: "",
        placeId: "",
        formattedAddress: "",
      });
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    return matchIsValidTel(phoneNumber);
  };

  const validateForm = () => {
    const errors = {
      email: formData.email === "" || !validateEmail(formData.email),
      phoneNumber:
        formData.phoneNumber === "" ||
        !validatePhoneNumber(formData.phoneNumber),
      vendorName: formData.vendorName === "",
      firstName: formData.firstName === "",
      lastName: formData.lastName === "",
      location: formData.location === "",
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const getPlaceDetails = async (placeId: string) => {
    const response = await fetch(
      `${endpoints.getDetailsByPlaceId}?placeId=${placeId}`,
    );
    const data = await response.json();
    if (data.status === "OK") {
      return data.result;
    } else {
      console.error("Error fetching place details:", data.status);
      return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    if (validateForm()) {
      setIsLoading(true);
      const placeDetails = await getPlaceDetails(formData.placeId);
      if (placeDetails) {
        const geocodingLocation = {
          lat: placeDetails.geometry.location.lat,
          lng: placeDetails.geometry.location.lng,
        };

        try {
          await apiHelper(endpoints.addVendor, {
            method: "POST",
            body: {
              ...formData,
              geocodingLocation,
            },
            bypassTokenCheck: true,
          });
          setOpenDialog(true);
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(t("Email_Already_Exists"));
          } else if (
            typeof error === "object" &&
            error !== null &&
            "response" in error
          ) {
            const errResponse = (error as { response: any }).response;
            if (errResponse?.data) {
              setErrorMessage(errResponse.data);
            } else {
              setErrorMessage(
                t("An_unexpected_error_occurred_Please_try_again"),
              );
            }
          } else {
            setErrorMessage(t("An_unexpected_error_occurred_Please_try_again"));
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleCloseDialog = () => {
    navigate("/landing");
  };

  const handleTermsModalOpen = () => {
    setOpenTermsModal(true);
  };

  const handleTermsModalClose = () => {
    setOpenTermsModal(false);
  };

  const handleTermsModalAgree = () => {
    setIsTermsChecked(true);
    setOpenTermsModal(false);
  };

  const handleTermsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsTermsChecked(event.target.checked);
  };

  return (
    <div className="landing-page">
      <TopBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          paddingTop: "5vh",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: onPrimary,
            padding: "5vh",
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              top: "10px",
              left: "10px",
            }}
          >
            <ArrowBack />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ fontFamily: "Poppins", fontWeight: 600 }}
            >
              {t("Sign_Up")}
            </Typography>
            <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
              <MuiTelInput
                required
                fullWidth
                autoFocus
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
                defaultCountry="HK"
                label={t("Phone_Number")}
                name="phoneNumber"
                error={formErrors.phoneNumber}
                helperText={
                  formErrors.phoneNumber &&
                  t("Please_enter_a_valid_phone_number")
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("Email")}
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
                error={formErrors.email}
                helperText={
                  formErrors.email && t("Please_enter_a_valid_email_address")
                }
              />
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label={t("First_Name")}
                  name="firstName"
                  autoComplete="given-name"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={formErrors.firstName}
                  helperText={
                    formErrors.firstName && t("First_name_is_required")
                  }
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label={t("Last_Name")}
                  name="lastName"
                  autoComplete="family-name"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={formErrors.lastName}
                  helperText={formErrors.lastName && t("Last_name_is_required")}
                />
              </Box>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="vendorName"
                label={t("Vendor_Name")}
                name="vendorName"
                autoComplete="vendorName"
                value={formData.vendorName}
                onChange={handleChange}
                error={formErrors.vendorName}
                helperText={
                  formErrors.vendorName && t("Vendor_name_is_required")
                }
              />
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_PLACE_API_KEY}
                autocompletionRequest={{
                  componentRestrictions: { country: "HK" },
                }}
                inputValue={formData.location}
                setInputValue={(value) => handleLocationChange(value)}
                value={selectedOption}
                setValue={(newOption) => {
                  handleSelectedLocation(newOption);
                }}
                label={t("Vendor_Location")}
                inputProps={{
                  label: t("Vendor_Location"),
                  required: true,
                  fullWidth: true,
                  margin: "normal",
                  variant: "outlined",
                  error: formErrors.location,
                  helperText: formErrors.location && t("Location_is_required"),
                }}
                minLengthAutocomplete={2}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="unitNumber"
                label={t("Detail_Address")}
                name="unitNumber"
                autoComplete="unitNumber"
                value={formData.unitNumber}
                onChange={handleChange}
              />
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTermsChecked}
                      onChange={handleTermsCheckboxChange}
                      name="terms"
                      color="primary"
                    />
                  }
                  label=""
                />
                <Typography variant="body1" ml={-2}>
                  {t("i_agree_to")}{" "}
                  <Link
                    onClick={handleTermsModalOpen}
                    sx={{ cursor: "pointer" }}
                  >
                    {t("terms_and_conditions")}
                  </Link>
                </Typography>
              </Box>
              <Box sx={{ mt: 2, mb: 2, position: "relative" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !isTermsChecked}
                >
                  {t("Sign_Up")}
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
              <Typography variant="body2">
                {t("Already_have_an_account")}{" "}
                <Link onClick={handleLogin} sx={{ cursor: "pointer" }}>
                  {t("Sign_In")}
                </Link>
              </Typography>
              {errorMessage && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {errorMessage}
                </Alert>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      <Dialog open={openTermsModal} onClose={handleTermsModalClose}>
        <DialogTitle>{t("terms_and_conditions")}</DialogTitle>
        <DialogContent dividers>
          {(
            t("terms_conditions_text", {
              returnObjects: true,
            }) as string[]
          ).map((text: string, index: number) => (
            <Typography key={index} variant="body1" sx={{ mb: 1 }}>
              {text}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleTermsModalClose}
            variant="contained"
            color="inherit"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleTermsModalAgree}
            variant="contained"
            color="primary"
          >
            {t("i_agree")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t("Application_Submitted")}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            {t("Thank_you_for_signing_up")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
          >
            {t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </div>
  );
};

export default Signup;
