import React from "react";
import { Box, Link as MuiLink, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import colors from "../config/colors";

const StyledLink = styled(MuiLink)(() => ({
  color: "inherit",
  textDecoration: "none",
}));

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        py: 2,
        backgroundColor: "#f5f5f5",
        textAlign: "center",
        color: colors.darkText,
      }}
    >
      <StyledLink
        href="mailto:support@yum-go.com"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EmailIcon sx={{ marginRight: 1 }} />
        <Typography variant="body2">support@yum-go.com</Typography>
      </StyledLink>
      <Typography variant="body2" sx={{ mt: 1 }}>
        © 2024 YumGo {t("all_rights_reserved")} |{" "}
        <Link to="https://www.yum-go.com/">{t("visit_official_website")}</Link>{" "}
        |{" "}
        <Link to="https://yumgo-public-files.s3.ap-northeast-1.amazonaws.com/yumgo-partner.apk">
          {t("download_app")}
        </Link>
      </Typography>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2">
          <Link
            to="/privacy-policy"
            style={{
              textDecoration: "none",
              color: colors.primary,
              margin: "0 8px",
            }}
          >
            {t("privacy_policy")}
          </Link>
          |
          <Link
            to="/terms-and-conditions"
            style={{
              textDecoration: "none",
              color: colors.primary,
              margin: "0 8px",
            }}
          >
            {t("terms_and_conditions")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
