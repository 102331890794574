import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import apiHelper from "../helper/apiHelper";
import endpoints from "../config/apiConfig";
import ResetPasswordDialog from "../components/ResetPasswordDialog";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

type VendorInfo = {
  vendorName: string;
  ownerName: string;
  address: string;
  unitNumber: string;
  email: string;
  phoneNumber: string;
  image: File | null;
  formattedAddress: string;
  placeId: string;
};

type Errors = {
  phoneNumber: boolean;
  address: boolean;
};

const VendorProfilePage = () => {
  const { t } = useTranslation();
  const vendorId = localStorage.getItem("vendorId");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({
    phoneNumber: false,
    address: false,
  });
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const [vendor, setVendor] = useState<VendorInfo>({
    vendorName: "",
    ownerName: "",
    address: "",
    unitNumber: "",
    email: "",
    phoneNumber: "",
    image: null,
    formattedAddress: "",
    placeId: "",
  });

  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

  useEffect(() => {
    const fetchVendorInfo = async () => {
      setLoading(true);
      try {
        if (vendorId) {
          const data = await apiHelper(
            `${endpoints.getVendorInfo}/${vendorId}`,
            {
              method: "GET",
            },
          );
          setVendor({
            vendorName: data.name,
            ownerName: `${data.firstName} ${data.lastName}`,
            address: data.formattedAddress,
            unitNumber: data.unitNumber || "",
            email: data.email,
            phoneNumber: data.phoneNumber,
            image: null,
            formattedAddress: data.formattedAddress,
            placeId: data.placeId,
          });
        }
      } catch (error) {
        console.error("Error fetching vendor info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVendorInfo();
  }, [vendorId]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setVendor((prevVendor) => ({
      ...prevVendor,
      [name]: value,
    }));
    if (errors[name as keyof Errors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name as keyof Errors]: false,
      }));
    }
  };

  const getPlaceDetails = async (placeId: string) => {
    const response = await fetch(
      `${endpoints.getDetailsByPlaceId}?placeId=${placeId}`,
    );
    const data = await response.json();
    if (data.status === "OK") {
      return data.result;
    } else {
      console.error("Error fetching place details:", data.status);
      return null;
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^[0-9]{10,15}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateForm = () => {
    const newErrors: Errors = {
      phoneNumber:
        vendor.phoneNumber === "" || !validatePhoneNumber(vendor.phoneNumber),
      address: vendor.address === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    try {
      const placeDetails = await getPlaceDetails(vendor.placeId);

      if (placeDetails) {
        const geocodingLocation = {
          lat: placeDetails.geometry.location.lat,
          lng: placeDetails.geometry.location.lng,
        };

        const payload = {
          ...vendor,
          geocodingLocation: geocodingLocation,
          vendorId,
        };

        await apiHelper(endpoints.updateVendorInfo, {
          method: "POST",
          body: payload,
        });
        setSuccessSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating vendor information:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        sx={{ mt: 1, mx: 4 }}
      >
        <Typography component="h1" variant="h5">
          {t("VendorProfile.My_Profile")}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={2}>
            <Button
              variant="outlined"
              sx={{ mt: 1, mb: 2 }}
              onClick={() => setResetPasswordOpen(true)}
              disabled={loading}
            >
              {t("VendorProfile.Reset_Password")}
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1, mb: 2 }}
              disabled={loading}
            >
              {t("VendorProfile.Save")}
            </Button>
          </Box>
        </Box>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                label={t("VendorProfile.Vendor_Name")}
                name="vendorName"
                value={vendor.vendorName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                label={t("VendorProfile.Owner_Name")}
                name="ownerName"
                value={vendor.ownerName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label={t("VendorProfile.Email_Address")}
                name="email"
                value={vendor.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label={t("VendorProfile.Phone_Number")}
                name="phoneNumber"
                value={vendor.phoneNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={errors.phoneNumber}
                helperText={
                  errors.phoneNumber && t("VendorProfile.Valid_Phone_Number")
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("Detail_Address")}
                name="unitNumber"
                value={vendor.unitNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label={t("VendorProfile.Address")}
                name="address"
                value={vendor.address}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled
              />
            </Grid>
          </Grid>
        )}

        <ResetPasswordDialog
          open={resetPasswordOpen}
          onClose={() => setResetPasswordOpen(false)}
        />
      </Box>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success">
          {t("VendorProfile.Profile_Updated_Successfully")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default VendorProfilePage;
