import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import apiHelper from "../helper/apiHelper";
import { VENDOR_SERVICE_URL } from "../config/apiConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

interface OperationPeriod {
  openTime: string;
  closeTime: string;
}

interface DayOperationHours {
  dayOfWeek: string;
  periods: OperationPeriod[];
  isOpen: boolean;
  error?: string;
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const VendorOperationHoursPage: React.FC = () => {
  const { t } = useTranslation();
  const vendorId = localStorage.getItem("vendorId") || "";
  const [operationHours, setOperationHours] = useState<DayOperationHours[]>(
    daysOfWeek.map((day) => ({
      dayOfWeek: day,
      periods: [
        {
          openTime: "07:00",
          closeTime: "21:00",
        },
      ],
      isOpen: true,
    })),
  );
  const [applyToAll, setApplyToAll] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    severity: "success" | "error";
    message: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchOperationHours = useCallback(async () => {
    try {
      setLoading(true);
      const data = await apiHelper(
        `${VENDOR_SERVICE_URL}/vendors/${vendorId}/operation-hours`,
        {
          method: "GET",
        },
      );
      setOperationHours(
        data.weeklySchedule.map((day: any) => ({
          ...day,
          isOpen: day.periods.length > 0,
        })),
      );
    } catch (error) {
      console.error("Failed to fetch operation hours:", error);
    } finally {
      setLoading(false);
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorId) {
      fetchOperationHours();
    }
  }, [vendorId, fetchOperationHours]);

  const clearErrorMessage = () => {
    setOperationHours((prev) => {
      const updated = [...prev];
      updated[0].error = "";
      return updated;
    });
  };

  const handleToggleDay = (dayIndex: number) => {
    setOperationHours((prev) => {
      const updated = [...prev];
      updated[dayIndex].isOpen = !updated[dayIndex].isOpen;
      if (!updated[dayIndex].isOpen) {
        updated[dayIndex].periods = [];
      } else {
        updated[dayIndex].periods = [
          {
            openTime: "07:00",
            closeTime: "21:00",
          },
        ];
      }
      return updated;
    });
  };

  const handleAddPeriod = (dayIndex: number) => {
    setOperationHours((prev) => {
      const updated = [...prev];
      if (updated[dayIndex].periods.length < 2) {
        updated[dayIndex].periods.push({
          openTime: "07:00",
          closeTime: "21:00",
        });
      }
      return updated;
    });
  };

  const handleRemovePeriod = (dayIndex: number, periodIndex: number) => {
    setOperationHours((prev) => {
      const updated = [...prev];
      updated[dayIndex].periods.splice(periodIndex, 1);
      return updated;
    });
  };

  const handleTimeChange = (
    dayIndex: number,
    periodIndex: number,
    field: "openTime" | "closeTime",
    value: string,
  ) => {
    if (value >= "07:00" && value <= "21:00") {
      clearErrorMessage();
      setOperationHours((prev) => {
        const updated = [...prev];
        updated[dayIndex].periods[periodIndex][field] = value;
        return updated;
      });
    }
  };

  const handleApplyToAll = () => {
    if (operationHours[0].periods.length === 0) handleToggleDay(0);
    clearErrorMessage();
    setApplyToAll((prev) => !prev);
  };

  const validateOperationHours = () => {
    let isValid = true;
    setOperationHours((prev) =>
      prev.map((day) => {
        let error = "";
        if (day.isOpen) {
          day.periods.forEach((period, index) => {
            if (period.openTime >= period.closeTime) {
              error = t("open_time_earlier_than_close_time");
              isValid = false;
            }
            if (
              index === 1 &&
              day.periods[0].closeTime >= day.periods[1].openTime
            ) {
              error = t("first_period_before_second");
              isValid = false;
            }
          });
        }
        return { ...day, error };
      }),
    );
    return isValid;
  };

  const handleSave = async () => {
    if (!validateOperationHours()) {
      setSnackbar({
        severity: "error",
        message: t("fix_error_before_save"),
      });
      return;
    }

    try {
      setLoading(true);
      let scheduleToSave = [...operationHours];
      if (applyToAll) {
        // Apply the first day's schedule to all days
        scheduleToSave = daysOfWeek.map((day) => ({
          dayOfWeek: day,
          isOpen: operationHours[0].isOpen,
          periods: [...operationHours[0].periods],
        }));
      }

      await apiHelper(
        `${VENDOR_SERVICE_URL}/vendors/${vendorId}/${operationHours.length === 0 ? "add-operation-hours" : "update-operation-hours"}`,
        {
          method: operationHours.length === 0 ? "POST" : "PUT",
          body: {
            vendorId,
            weeklySchedule: scheduleToSave,
          },
        },
      );
      await fetchOperationHours();
      if (applyToAll) handleApplyToAll();

      setSnackbar({
        severity: "success",
        message: t("Operation_hours_saved_successfully!"),
      });
    } catch (error: any) {
      console.error("Failed to save operation hours:", error);
      setSnackbar({
        severity: "error",
        message: error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
      <Typography component="h1" variant="h5">
        {t("Manage_Operation_Hours")}
      </Typography>
      <Typography component="h1" variant="caption" mt={1}>
        {t("yumgo_operation_hours")}
      </Typography>
      <Typography component="h1" variant="caption" mt={1}>
        {t("meal_type_time")}
      </Typography>
      <Typography component="h1" variant="caption" mt={1}>
        {t("max_of_2_time_slot")}
      </Typography>
      <FormControlLabel
        sx={{ mt: 4 }}
        control={
          <Switch
            checked={applyToAll}
            onChange={handleApplyToAll}
            color="primary"
          />
        }
        label={t("apply_to_all_days")}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        (applyToAll ? [operationHours[0]] : operationHours).map(
          (day, dayIndex) => (
            <Box
              key={day.dayOfWeek}
              sx={{ mt: 2, border: "1px solid #e0e0e0", p: 2, borderRadius: 1 }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {!applyToAll ? (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={day.isOpen || false}
                          onChange={() => handleToggleDay(dayIndex)}
                          color="primary"
                          disabled={applyToAll}
                        />
                      }
                      label={t(day.dayOfWeek.toLowerCase())}
                    />
                  ) : (
                    <Typography component="h1" variant="body1">
                      {t("all_days")}
                    </Typography>
                  )}
                </Grid>
                {day.isOpen && (
                  <>
                    {day.periods?.map((period, periodIndex) => (
                      <Grid
                        container
                        spacing={2}
                        key={periodIndex}
                        alignItems="center"
                      >
                        <Grid item xs={4}>
                          <TextField
                            sx={{ mt: 2, ml: 2 }}
                            label={t("Open_Time")}
                            type="time"
                            value={period.openTime}
                            onChange={(e) =>
                              handleTimeChange(
                                dayIndex,
                                periodIndex,
                                "openTime",
                                e.target.value,
                              )
                            }
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ step: 300 }} // 5 min step
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            sx={{ mt: 2, ml: 2 }}
                            label={t("Close_Time")}
                            type="time"
                            value={period.closeTime}
                            onChange={(e) =>
                              handleTimeChange(
                                dayIndex,
                                periodIndex,
                                "closeTime",
                                e.target.value,
                              )
                            }
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ step: 300 }} // 5 min step
                          />
                        </Grid>
                        <Grid item xs={2} sx={{ mt: 2, ml: 1 }}>
                          {periodIndex === 0 ? (
                            <IconButton
                              color="primary"
                              onClick={() => handleAddPeriod(dayIndex)}
                              disabled={day.periods?.length === 2}
                            >
                              <AddIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemovePeriod(dayIndex, periodIndex)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    {day.error && (
                      <Typography color="error" variant="caption" mt={1} ml={2}>
                        {day.error}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          ),
        )
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ mt: 4 }}
        disabled={loading}
      >
        {t("Save_Operation_Hours")}
      </Button>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={4000}
        onClose={() => setSnackbar(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbar(null)} severity={snackbar?.severity}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default VendorOperationHoursPage;
