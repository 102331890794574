import React from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import TopBar from "../components/TopBar/TopBar";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPolicyPage = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Determine which document to show based on the current language
  let selectedDocUri;
  switch (currentLanguage) {
    case "zh-Hans":
      selectedDocUri =
        "https://grubgo-partner-images4f1a7-tokyo.s3.ap-northeast-1.amazonaws.com/public/YumGo%E5%90%88%E4%BD%9C%E5%95%86%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96%E7%AE%80%E4%BD%93.docx";
      break;
    case "zh-Hant":
      selectedDocUri =
        "https://grubgo-partner-images4f1a7-tokyo.s3.ap-northeast-1.amazonaws.com/public/YumGo%E5%90%88%E4%BD%9C%E5%95%86%E9%9A%B1%E7%A7%81%E6%94%BF%E7%AD%96%E7%B9%81%E4%BD%93.docx";
      break;
    default:
      selectedDocUri =
        "https://grubgo-partner-images4f1a7-tokyo.s3.ap-northeast-1.amazonaws.com/public/VendorPrivacyPolicy.docx";
  }

  return (
    <Box>
      <TopBar />
      <div>
        <DocViewer
          documents={[{ uri: selectedDocUri, fileType: "docx" }]}
          pluginRenderers={DocViewerRenderers}
          style={{ height: "90vh" }}
        />
      </div>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicyPage;
